@import '/src/storybook/styles/variables';

.results-grid {
  padding: 0px 35px;
  position: relative;
  .results-row {
    background-color: white;
    height: 60px;
    margin-bottom: 12px;
    border: 1px solid $box-border-color;
    border-radius: 3px;
    &.selected {
      background-color: rgba(232, 234, 238, 0.5);
      > div:nth-child(2) {
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
      }
    }
    > div {
      display: flex;
      align-self: center;
      position: relative;
      &:first-child {
        justify-content: center;
        label {
          padding-left: 22px;
        }
      }
      .bom-button {
        width: 46px;
        height: 24px;
        padding: 0px;
        font-size: 12px;
        color: var(--primary-color);
        background-color: white;
        border: 1px solid var(--primary-color);
        border-radius: 12px;
        &:hover {
          background-color: rgba(108,7,224,0.1);
        }
      }
      .help-icon {
        margin-left: 8px;
      }
      .red {
        color: $error-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  > svg { //Watermark
    position: absolute;
    left: 25%;
    top: 50px;
  }
}