@import '/src/storybook/styles/variables';

.customer-and-deal.requirement-box {
  .requirement-row {
    justify-content: space-between;
    margin-top: 24px;
    .radio {
      margin-right: 24px;
    }
  }
}