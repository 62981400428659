@mixin disabled-component {
  filter: blur(3px);
  opacity: 0.5;
  pointer-events: none;
}

@mixin radio-element {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px;
  margin-top: 10px;
  cursor: pointer;

  .radio-input {
    display: inherit;
    align-items: center;
    input {
      display: none;
      & + .radio-control::before {
        content: "";
        width: 9px;
        height: 9px;
        box-shadow: inset 9px 9px var(--primary-color);
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
        transform: scale(0);
      }

      &:checked + .radio-control::before {
        transform: scale(1);
      }
    }

    .radio-control {
      display: grid;
      place-items: center;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 1px solid $border-color;
    }
  }
}