@import '/src/storybook/styles/variables';

.requirements-pane .output-control.requirement-box {
  .requirement-row {
    margin-top: 20px;
    .radio {
      margin-left: 8px;
    }
  }
  .vendors-title {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .vendor-container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border: 1px solid $border-color;
    &:not(:last-child) {
      border-bottom: none;
    }
    button {
      display: none;
      opacity: 0.7;
      padding: 0px 10px;
      height: 30px;
      border: 1px solid $border-color;
      font-size: 11px;
      font-weight: normal;
    }
    &:hover {
      background-color: $component-background;
      button {
        display: block;
      }
    }
  }

}