@import '/src/storybook/styles/variables';

.partner-login {
  height: 100%;
  background-color: $component-background;
  .header {
    background-color: var(--primary-color);
    height: 387px;
    padding: 30px;
  }
  .container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    color: white;
    .title {
      margin-top: 44px;
      font-size: 44px;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
    .sub-title {
      font-size: 20px;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 32px;
    }
    .text {
      font-size: 16px;
      line-height: 27px;
      .documentation {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .login-options {
      margin-top: 70px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .box {
        border: $border;
        cursor: auto;
        text-align: center;
        padding: 50px;
        width: 454px;
        height: 269px;
        background-color: white;
        .sign-in-text {
          color: black;
          opacity: 0.17;
        }
        .work-for {
          color: black;
          font-size: 28px;
          font-weight: bold;
          margin-top: 36px;
          span {
            color: var(--primary-color);
            font-weight: normal;
          }
        }
        &.expired-token {
          .work-for {
            font-size: 20px;
          }
          &:last-child button {
            margin-top: 69px;
          }
        }
        .my-email {
          margin-top: 39px;
          color: black;
          &.no-domain {
            margin-top: 28px;
          }
        }
        button {
          display: inline-block;
          margin-top: 69px;
          width: 234px;
          height: 45px;
        }
        &:last-child button {
          margin-top: 15px;
          &.no-domain {
            margin-top: 58px;
          }
          &.vartopia-login {
            margin-top: 128px;
          }
        }
      }
    }
  }
}