:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}
$link-color: #0073cf;
$border-color: #e2e2e2;
$input-color: #4e4e4e;
$component-background: #f5f5f6;
$box-border-color: #e8e8e8;
$secondary-text-color: #6d7278;
$secondary-background-color: #ebeced;
$pdf-background-color: #ececee;
$error-color: #dd2524;
$separator-color: #dcdcdc;
$success-color: #36C7A0;
$selected-row-background: rgba(245,245,246,0.7);

$border: 1px solid $border-color;
$border-radius: 1px;
$component-height: 40px;
$component-small-width: 94px;
$component-medium-width: 188px;
$shadow: 0 7px 17px 0 rgba(0,0,0,0.07);
$requirements-padding: 17px 25px 17px 30px;
$requirements-pane-width: 342px;
$label-min-width: 53px;

@-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}