@import '../../styles/variables';
@import '../../styles/mixins';

.radio-input-component {
  .radio-element {
    @include radio-element;

    .radio-label {
      user-select: none;
      &.disabled input, &.disabled > b {
        opacity: 0.2;
      }
      div {
        display: inline-block;
        min-width: $label-min-width;
      }
      > b {
        margin-left: 18px;
      }
    }
  }
}
