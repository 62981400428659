@import '../../styles/variables';

.button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: $border-radius;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--primary-color);
  &:hover {
    background-color: var(--primary-color-hover);
  }
  &.black {
    background-color: black;
    &:hover:not(:disabled) {
      background-color: lighten(black, 20%);
    }
  }
  &.secondary {
    color: black;
    background-color: transparent;
  }
  &.okta {
    background-color: $link-color;
    margin-top: 30px;
    display: flex;
  }
  &:disabled {
    background-color: grey;
  }
}