@import '/src/storybook/styles/variables';

//TODO: unite this css with NotionDatabaseValidation
.notion-data-copy {
  padding: 20px;
  font-size: 16px;
  .action-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .dropdown {
      margin: 0 15px;
    }
  }
  .status {
    margin: 20px;
    color: $success-color;
    font-weight: 600;
    &.has-errors {
      color: $secondary-text-color;
    }
  }
  .errors {
    margin-top: 20px;
    li {
      color: $error-color;
      list-style: square;
      margin-left: 40px;
    }
  }
  .loader {
    position: inherit;
    margin-top: 0px;
  }
}