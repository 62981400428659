@import '/src/storybook/styles/variables';

.export-content {
  $contentWidth: 1684px;
  width: $contentWidth;
  position: relative;

  /* Header */
  .header {
    width: $contentWidth;
    height: 22px;
    background-color: var(--primary-color);
    padding: 16px 20px 15px;
  }

  .section-header {
    width: $contentWidth - 100px - 16px;
    display: flex;
    align-items: center;
    color: white;
    background-color: black;
    opacity: 0.75;
    margin: 20px auto 12px;
    padding: 0px 8px;
    height: 30px;
  }

  /* Requirements */
  .requirements {
    width: $contentWidth - 100px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .column {
      &:not(:first-child) {
        margin-left: 14px;
      }
      border-radius: 10px;
      .column-title {
        border: 1px solid $separator-color;
        border-radius: 10px 10px 0px 0px;
        padding: 0px 17px;
        background-color: white;
        height: 40px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .column-content {
        background-color: $pdf-background-color;
        border-radius: 0px 0px 10px 10px;
        .column-content-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 17px;
          border: 1px solid $separator-color;
          height: 45px;
          .bold {
            font-weight: bold;
          }
          &:last-child {
            border-radius: 0px 0px 10px 10px;
          }
          > div {
            display: grid;
            &.largeWidth {
              grid-template-columns: 1fr 60px;
            }
            &.smallWidth {
              grid-template-columns: 1fr 30px;
            }
            justify-content: space-between;
            align-items: center;
            justify-items: center;
            > div:first-child {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0,0,0,0.1);
              border-radius: 6px;
              width: 90px;
              height: 28px;
            }
            > div:last-child {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}