@import '../../styles/variables';

.success-notification {
  padding: 12px 14px;
  background-color: $success-color;
  width: 100%;
  display: inline-flex;
  border-radius: 6px;

  .notification-text {
    color: white;
    font-size: 16px;
    align-self: center;
    margin-left: 10px;
    font-weight: bold;
  }
}