@import '/src/storybook/styles/variables';

.performance {
  .rc-tooltip-inner {
    width: 238px;
  }
  .requirement-row {
    margin-top: 24px;
    &.performance-type {
      margin-top: 10px;
    }
    &.iops, &.bw {
      margin-top: 8px;
    }
    &.rw b {
      margin-right: 40px;
    }
    .radio-label {
      margin-left: 4px;
      margin-right: 38px;
    }
    div {
      min-width: $label-min-width;
    }
  }

  .io-size, .io-size-by-sizing {
    .radio-background {
      min-width: 36px;
    }
    .radio {
      * {
        width: 36px;
      }

      input:checked:nth-child(3) ~ .radio-background {
        transform: translateX(36px);
      }
    }
  }
  .io-size {
    position: relative;
    display: flex;
    margin-left: 76px;
    .radio-input-component .radio-input .radio-control {
      display: none;
    }
    .radio {
      position: absolute;
      right: -4px;
      align-self: center;
      margin-top: 10px;
    }
    .radio-element .radio-label {
      input {
        width: 74px;
      }
      div {
        min-width: 46px;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
    b {
      font-size: 11px;
    }
  }
}