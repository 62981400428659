@import '../../styles/variables';

.container {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;
  line-height: 22px;
  height: 22px;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    &:checked ~ .checkmark {
      background-color: var(--primary-color);
      &:after {
        display: block;
      }
    }
    &:not(:checked) ~ .checkmark:after {
      display: none;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $component-background;
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}