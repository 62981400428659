@import '../../styles/variables';

.help-icon {
  align-self: center;
  cursor: pointer;
  &.visible, &:hover {
    svg path.background {
      fill: var(--primary-color);
    }
  }  
}

.rc-tooltip {
  opacity: 1 !important;
  &.rc-tooltip-placement-bottom {
    box-shadow: $shadow;
    border-color: $box-border-color;
  }
  .rc-tooltip-inner {
    width: 216px;
    padding: 25px 20px;
    border-radius: 5px;
    b {
      font-size: 14px;
    }
    ul {
      padding-left: 14px;
    }
  }
}
