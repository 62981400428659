@import '/src/storybook/styles/variables';


.result-help-tooltip.rc-tooltip, .sub-tooltip.rc-tooltip {
  .rc-tooltip-inner {
    padding: 0px;
    width: 255px;
    > div > div {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      b {
        font-size: 12px;
      }
      > div.priceCell {
        width: 80px;
        &:first-child {
          width: 150px;
        }
      }
      > div.filesAndDirsCell {
        width: 110px;
        text-align: center;
      }
      &.selected {
        background-color: $selected-row-background;
      }
    }
    .tooltipValue {
      display: flex;
      > div {
        margin-right: 5px;
        color: $secondary-text-color;
        &.red {
          color: $error-color;
        }
      }
    }
  }
  &.performance {
    .rc-tooltip-inner {
      width: 200px;
    }
    .rc-tooltip-arrow {
      left: 90%;
    }
  }
  &:not(.sub-tooltip.rc-tooltip) .rc-tooltip-inner > div > div {
    &:first-child {
      padding-bottom: 0px;
      color: $secondary-text-color;
      font-size: 11px;
    }
    &:not(:first-child) {
      border-bottom: 1px solid $box-border-color;
    }
  }
  &.big .rc-tooltip-inner {
    width: 600px;
  }
}