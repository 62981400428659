@import '/src/storybook/styles/variables';

.threshold-tooltip.rc-tooltip {
  .rc-tooltip-inner {
    padding: 0px;
    div {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      &.warning-part {
        border-bottom: 1px solid $box-border-color;
        font-size: 11px;
        font-weight: bold;
        .exclamationMark {
          min-width: 16px;
          height: 16px;
          background-color: $error-color;
          border-radius: 10px;
          text-align: center;
          color: white;
          margin-right: 8px;
        }
      }
    }
  }
}