.login {
  padding: 30px;
  > div {
    margin: 0px 25%;
    h1 {
      font-size: 44px;
      font-weight: bold;
      margin-top: 110px;
      margin-bottom: 30px;
    }
    .google {
      display: block;
      margin-top: 30px;
    }
  }
}