@import '/src/storybook/styles/variables';

.rc-tooltip.sub-tooltip {
  .rc-tooltip-inner {
    min-width: 300px;
    > div > div {
      border-bottom: 1px solid $box-border-color;
    }
  }
  .rc-tooltip-arrow {
    display: none;
  }
}