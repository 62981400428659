@import '/src/storybook/styles/variables';

.export-content .options-content {
  padding-bottom: 20px;
  .cell {
    padding: 16px 16px 14px;
    font-size: 14px;
    &.values-row {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  .results {
    padding: 0px 50px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 9% 13% 7% 6% 4% 7% 7% 5% 6% 5% 5% 6% 4% 5% 5% 6%;
    &:not(:first-child) .cell.header-row:not(:first-child) {
      display: none;
    }
    &:first-child .cell {
      &.header-row {
        margin-top: 13px;
        &:nth-child(n+10) {
          font-size: 11px;
        }
      }
    }
    .cell:not(:first-child):not(:nth-last-child(16)) {
      border-right: $border;
    }
    .cell.header-row {
      margin-top: 20px;
      &:first-child {
        color: var(--primary-color);
        align-self: end;
        font-size: 22px;
        font-family: 'MontserratBold';
        padding-left: 0px;
      }
      &:not(:first-child), &:nth-child(2) {
        border-top: $border;
      }
      &:nth-child(2) {
        border-left: $border;
      }
    }
    .cell.full-row, .cell.full-row b {
      grid-column: 1 / 17;
      color: white;
      background-color: var(--primary-color);
      font-size: 20px;
    }
    .cell.values-row {
      margin-bottom: 10px;
      background-color: white;
      height: 18px;
    }
    .cell.header-row:nth-child(2), .cell.values-row:nth-last-child(15) {
      font-family: 'MontserratBold';
      font-size: 16px;
    }
  }
}