@import "./_containers.scss";
@import "./_types.scss";

@keyframes timer {
  0% { width: 100%; }
  100% { width: 0%; }
}

.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification__item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.notification-container--top-full .notification__item,
.notification-container--bottom-full .notification__item {
  margin-bottom: 0;
  border-radius: 0;
}

.notification__timer {
  width: 100%;
  height: 3px;
  margin-top: 10px;
  border-radius: 5px;
}
.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}
.notification__title {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.notification__message {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
.notification__content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}
.notification__close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;

  &::after {
    content: '\D7';
    position: absolute;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    left: 50%;
    top: 50%;
  }
}

.notification-container--mobile-top .notification__item,
.notification-container--mobile-bottom .notification__item,
.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
  max-width: 100%;
  width: 100%;
}

.notification-container--top-right .notification,
.notification-container--bottom-right .notification {
  margin-left: auto;
}

.notification-container--top-left .notification,
.notification-container--bottom-left .notification {
  margin-right: auto;
}

.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
  margin-left: auto;
  margin-right: auto;
}