:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media (prefers-reduced-motion: reduce), print {
  .animate__animated {
    transition-duration: 1ms !important;
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 53%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0)scaleY(1.1);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0)scaleY(1.05);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0)scaleY(.95);
    transform: translateZ(0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0)scaleY(1.02);
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

@keyframes bounce {
  0%, 20%, 53%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0)scaleY(1.1);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0)scaleY(1.05);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0)scaleY(.95);
    transform: translateZ(0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0)scaleY(1.02);
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shakeX {
  0%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shakeX {
  0%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX;
}

@-webkit-keyframes shakeY {
  0%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes shakeY {
  0%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px)rotateY(-9deg);
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px)rotateY(7deg);
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px)rotateY(-5deg);
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px)rotateY(3deg);
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px)rotateY(-9deg);
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px)rotateY(7deg);
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px)rotateY(-5deg);
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px)rotateY(3deg);
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.animate__headShake {
  -webkit-animation-name: headShake;
  animation-name: headShake;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.animate__swing {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9)rotate(-3deg);
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9)rotate(-3deg);
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0)rotate(-5deg);
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0)rotate(3deg);
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0)rotate(-3deg);
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0)rotate(2deg);
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0)rotate(-1deg);
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0)rotate(-5deg);
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0)rotate(3deg);
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0)rotate(-3deg);
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0)rotate(2deg);
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0)rotate(-1deg);
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  0%, 11.1%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg)skewY(-12.5deg);
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg)skewY(6.25deg);
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg)skewY(-3.125deg);
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg)skewY(1.5625deg);
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-.78125deg)skewY(-.78125deg);
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(.390625deg)skewY(.390625deg);
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-.195313deg)skewY(-.195313deg);
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

@keyframes jello {
  0%, 11.1%, to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg)skewY(-12.5deg);
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg)skewY(6.25deg);
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg)skewY(-3.125deg);
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg)skewY(1.5625deg);
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-.78125deg)skewY(-.78125deg);
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(.390625deg)skewY(.390625deg);
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-.195313deg)skewY(-.195313deg);
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: jello;
  animation-name: jello;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes backInDown {
  0% {
    opacity: .7;
    -webkit-transform: translateY(-1200px)scale(.7);
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes backInDown {
  0% {
    opacity: .7;
    -webkit-transform: translateY(-1200px)scale(.7);
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown;
}

@-webkit-keyframes backInLeft {
  0% {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft;
}

@-webkit-keyframes backInRight {
  0% {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes backInRight {
  0% {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight;
}

@-webkit-keyframes backInUp {
  0% {
    opacity: .7;
    -webkit-transform: translateY(1200px)scale(.7);
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes backInUp {
  0% {
    opacity: .7;
    -webkit-transform: translateY(1200px)scale(.7);
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp;
}

@-webkit-keyframes backOutDown {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateY(700px)scale(.7);
    transform: translateY(700px)scale(.7);
  }
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateY(700px)scale(.7);
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  -webkit-animation-name: backOutDown;
  animation-name: backOutDown;
}

@-webkit-keyframes backOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
    transform: translateX(-2000px)scale(.7);
  }
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateX(-2000px)scale(.7);
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  -webkit-animation-name: backOutLeft;
  animation-name: backOutLeft;
}

@-webkit-keyframes backOutRight {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
    transform: translateX(2000px)scale(.7);
  }
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateX(0)scale(.7);
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateX(2000px)scale(.7);
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  -webkit-animation-name: backOutRight;
  animation-name: backOutRight;
}

@-webkit-keyframes backOutUp {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateY(-700px)scale(.7);
    transform: translateY(-700px)scale(.7);
  }
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    opacity: .7;
    -webkit-transform: translateY(0)scale(.7);
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    -webkit-transform: translateY(-700px)scale(.7);
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  -webkit-animation-name: backOutUp;
  animation-name: backOutUp;
}

@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__bounceIn {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0)scaleY(3);
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0)scaleY(.9);
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0)scaleY(.95);
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0)scaleY(.985);
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0)scaleY(3);
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0)scaleY(.9);
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0)scaleY(.95);
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0)scaleY(.985);
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0)scaleX(3);
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0)scaleX(1);
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0)scaleX(.98);
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0)scaleX(.995);
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0)scaleX(3);
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0)scaleX(1);
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0)scaleX(.98);
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0)scaleX(.995);
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0)scaleX(3);
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0)scaleX(1);
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0)scaleX(.98);
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0)scaleX(.995);
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0)scaleX(3);
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0)scaleX(1);
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0)scaleX(.98);
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0)scaleX(.995);
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0)scaleY(5);
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0)scaleY(.9);
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0)scaleY(.95);
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0)scaleY(.985);
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0)scaleY(5);
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0)scaleY(.9);
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0)scaleY(.95);
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0)scaleY(.985);
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0)scaleY(.985);
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0)scaleY(.9);
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0)scaleY(3);
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0)scaleY(.985);
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0)scaleY(.9);
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0)scaleY(3);
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0)scaleX(.9);
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0)scaleX(2);
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0)scaleX(.9);
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0)scaleX(2);
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0)scaleX(.9);
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0)scaleX(2);
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0)scaleX(.9);
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0)scaleX(2);
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0)scaleY(.985);
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0)scaleY(.9);
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0)scaleY(3);
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0)scaleY(.985);
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0)scaleY(.9);
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0)scaleY(3);
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInTopLeft {
  -webkit-animation-name: fadeInTopLeft;
  animation-name: fadeInTopLeft;
}

@-webkit-keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInTopRight {
  -webkit-animation-name: fadeInTopRight;
  animation-name: fadeInTopRight;
}

@-webkit-keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInBottomLeft {
  -webkit-animation-name: fadeInBottomLeft;
  animation-name: fadeInBottomLeft;
}

@-webkit-keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInBottomRight {
  -webkit-animation-name: fadeInBottomRight;
  animation-name: fadeInBottomRight;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }
}

@keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  -webkit-animation-name: fadeOutTopLeft;
  animation-name: fadeOutTopLeft;
}

@-webkit-keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
}

@keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  -webkit-animation-name: fadeOutTopRight;
  animation-name: fadeOutTopRight;
}

@-webkit-keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }
}

@keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  -webkit-animation-name: fadeOutBottomRight;
  animation-name: fadeOutBottomRight;
}

@-webkit-keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }
}

@keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  -webkit-animation-name: fadeOutBottomLeft;
  animation-name: fadeOutBottomLeft;
}

@-webkit-keyframes flip {
  0% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
  }

  40% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
  }

  50% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
  }

  80% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
    transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
  }

  to {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
  }
}

@keyframes flip {
  0% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
  }

  40% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
  }

  50% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
  }

  80% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
    transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
  }

  to {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateX(90deg);
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateX(-20deg);
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(10deg);
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    -webkit-transform: perspective(400px)rotateX(-5deg);
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateX(90deg);
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateX(-20deg);
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(10deg);
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    -webkit-transform: perspective(400px)rotateX(-5deg);
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animate__flipInX {
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateY(90deg);
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateY(-20deg);
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(10deg);
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    -webkit-transform: perspective(400px)rotateY(-5deg);
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateY(90deg);
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px)rotateY(-20deg);
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(10deg);
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    -webkit-transform: perspective(400px)rotateY(-5deg);
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animate__flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(-20deg);
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateX(90deg);
    transform: perspective(400px)rotateX(90deg);
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateX(-20deg);
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateX(90deg);
    transform: perspective(400px)rotateX(90deg);
  }
}

.animate__flipOutX {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(-15deg);
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateY(90deg);
    transform: perspective(400px)rotateY(90deg);
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    -webkit-transform: perspective(400px)rotateY(-15deg);
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px)rotateY(90deg);
    transform: perspective(400px)rotateY(90deg);
  }
}

.animate__flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)skewX(-30deg);
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)skewX(-30deg);
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__lightSpeedInRight {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)skewX(30deg);
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)skewX(30deg);
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)skewX(30deg);
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

@keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)skewX(30deg);
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)skewX(-30deg);
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

@keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)skewX(-30deg);
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rotateIn {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rotateInDownLeft {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rotateInDownRight {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rotateInUpLeft {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rotateInUpRight {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
  }
}

@keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
  }
}

.animate__rotateOut {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.animate__rotateOutDownLeft {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.animate__rotateOutDownRight {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.animate__rotateOutUpLeft {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.animate__rotateOutUpRight {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
  }

  40%, 80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
  }
}

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
  }

  40%, 80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(.1)rotate(30deg);
    transform: scale(.1)rotate(30deg);
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(.1)rotate(30deg);
    transform: scale(.1)rotate(30deg);
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)rotate(-120deg);
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0)rotate(-120deg);
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)rotate(120deg);
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0)rotate(120deg);
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

.animate__rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1)translate3d(-2000px, 0, 0);
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1)translate3d(-2000px, 0, 0);
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  -webkit-transform-origin: 0;
  transform-origin: 0;
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1)translate3d(2000px, 0, 0);
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1)translate3d(2000px, 0, 0);
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    -webkit-transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    -webkit-transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInDown {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInLeft {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInRight {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.success-notification {
  background-color: #36c7a0;
  border-radius: 6px;
  width: 100%;
  padding: 12px 14px;
  display: inline-flex;
}

.success-notification .notification-text {
  color: #fff;
  align-self: center;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: var(--primary-color);
  border: none;
  border-radius: 1px;
  outline: none;
  padding: 10px 15px;
  font-weight: bold;
}

.button:hover {
  background-color: var(--primary-color-hover);
}

.button.black {
  background-color: #000;
}

.button.black:hover:not(:disabled) {
  background-color: #333;
}

.button.secondary {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
}

.button.okta {
  background-color: #0073cf;
  margin-top: 30px;
  display: flex;
}

.button:disabled {
  background-color: gray;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.footer {
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 200px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.footer > div, .footer a {
  color: #fff;
  margin-right: 10px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.input {
  color: #4e4e4e;
  text-align: center;
  border: 1px solid #e2e2e2;
  border-radius: 1px;
  outline: none;
  height: 40px;
  font-size: 16px;
}

.input.small {
  width: 94px;
}

.input.medium {
  width: 188px;
}

.input.tag {
  color: rgba(0, 0, 0, .85);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: 11px;
  width: 56px;
  height: 21px;
  font-size: 12px;
}

.input.tag::-webkit-input-placeholder {
  color: #000;
  opacity: .85;
}

.input.tag::placeholder {
  color: #000;
  opacity: .85;
}

.input.tag:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.input.tag:focus::placeholder {
  color: rgba(0, 0, 0, 0);
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.expired-token {
  margin: 0 auto;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.partner-login {
  background-color: #f5f5f6;
  height: 100%;
}

.partner-login .header {
  background-color: var(--primary-color);
  height: 387px;
  padding: 30px;
}

.partner-login .container {
  color: #fff;
  flex-direction: column;
  width: 1140px;
  margin: 0 auto;
  display: flex;
}

.partner-login .container .title {
  margin-top: 44px;
  font-size: 44px;
  font-weight: bold;
}

.partner-login .container .title span {
  font-weight: normal;
}

.partner-login .container .sub-title {
  margin-top: 12px;
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: bold;
}

.partner-login .container .text {
  font-size: 16px;
  line-height: 27px;
}

.partner-login .container .text .documentation {
  font-weight: bold;
  text-decoration: underline;
}

.partner-login .container .login-options {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;
  display: flex;
}

.partner-login .container .login-options .box {
  cursor: auto;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  width: 454px;
  height: 269px;
  padding: 50px;
}

.partner-login .container .login-options .box .sign-in-text {
  color: #000;
  opacity: .17;
}

.partner-login .container .login-options .box .work-for {
  color: #000;
  margin-top: 36px;
  font-size: 28px;
  font-weight: bold;
}

.partner-login .container .login-options .box .work-for span {
  color: var(--primary-color);
  font-weight: normal;
}

.partner-login .container .login-options .box.expired-token .work-for {
  font-size: 20px;
}

.partner-login .container .login-options .box.expired-token:last-child button {
  margin-top: 69px;
}

.partner-login .container .login-options .box .my-email {
  color: #000;
  margin-top: 39px;
}

.partner-login .container .login-options .box .my-email.no-domain {
  margin-top: 28px;
}

.partner-login .container .login-options .box button {
  width: 234px;
  height: 45px;
  margin-top: 69px;
  display: inline-block;
}

.partner-login .container .login-options .box:last-child button {
  margin-top: 15px;
}

.partner-login .container .login-options .box:last-child button.no-domain {
  margin-top: 58px;
}

.partner-login .container .login-options .box:last-child button.vartopia-login {
  margin-top: 128px;
}

.login {
  padding: 30px;
}

.login > div {
  margin: 0 25%;
}

.login > div h1 {
  margin-top: 110px;
  margin-bottom: 30px;
  font-size: 44px;
  font-weight: bold;
}

.login > div .google {
  margin-top: 30px;
  display: block;
}

.page-404 {
  text-align: center;
}

.page-404 h1 {
  color: gray;
  margin-top: 200px;
  font-size: 100px;
}

.page-404 div {
  margin-top: 20px;
  font-size: 20px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tabs-container {
  flex-wrap: wrap;
  display: flex;
}

.tabs-container .tab {
  cursor: pointer;
  flex: 1;
  height: 15px;
  padding: 22px 20px 22px 30px;
  font-size: 12px;
  font-weight: bold;
}

.tabs-container .tab:first-child {
  border-right: 1px solid #e2e2e2;
}

.tabs-container .tab:not(:first-child) {
  padding: 22px 0;
}

.tabs-container .tab:not(.selected) {
  border-bottom: 1px solid #e2e2e2;
}

.tabs-container .tab.selected {
  color: var(--primary-color);
  border-top: 1px solid #e2e2e2;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.toggle {
  align-items: center;
  display: flex;
}

.toggle input {
  margin: 0;
  display: none;
}

.toggle input + label {
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 2em;
  width: 43px;
  height: 18px;
  padding: 5px;
  display: inline-block;
}

.toggle input + label:after, .toggle input + label:before {
  content: "";
  width: 45%;
  height: 100%;
  display: block;
  position: relative;
}

.toggle input + label:after {
  background: #fff;
  border-radius: 50%;
  transition: all .2s;
  left: 0;
}

.toggle input + label:before {
  display: none;
}

.toggle input:checked + label:after {
  left: 55%;
}

.toggle.boolean input:not(:checked) + label {
  background: #6d7278;
}

.toggle div {
  font-weight: 600;
}

.toggle div:first-child {
  margin-right: 40px;
}

.toggle div:last-child {
  margin-left: 28px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dropdown {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 188px;
  line-height: 1.1;
}

.dropdown > div {
  box-shadow: none;
  cursor: pointer;
  border-radius: 2px;
  height: 40px;
}

.dropdown > div, .dropdown > div:hover {
  border-color: #e2e2e2;
}

.dropdown > div span {
  display: none;
}

.dropdown > div svg path {
  fill: #000;
}

.dropdown .dropdown__control--menu-is-open svg {
  transition: all 1s ease-in-out;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropdown .dropdown__single-value {
  color: #000;
}

.export-dropdown {
  cursor: auto;
}

.export-dropdown .dropdown__control {
  border-radius: 4px;
  width: 100px;
}

.export-dropdown .dropdown__control > div > div {
  margin-left: 8px;
}

.export-dropdown .dropdown__control .dropdown__indicators {
  border: 4px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #000;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin: 16px 15px 15px 0;
}

.export-dropdown .dropdown__control .dropdown__indicators svg {
  display: none;
}

.export-dropdown .dropdown__control.dropdown__control--menu-is-open .dropdown__indicators {
  border-width: 0 4px 5px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
}

.export-dropdown .dropdown__menu {
  margin-top: 4px;
}

.export-dropdown .dropdown__menu .dropdown__menu-list {
  overflow-y: initial;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
}

.export-dropdown .dropdown__menu .dropdown__option {
  border-radius: 2px;
  padding-left: 6px;
}

.export-dropdown .dropdown__menu .dropdown__option > div {
  font-size: 12px;
  display: flex;
}

.export-dropdown .dropdown__menu .dropdown__option > div > span {
  padding-top: 4px;
  display: block;
}

.dropdown__menu, .dropdown__menu-portal {
  margin-top: -1px;
}

.dropdown__menu .dropdown__menu-list, .dropdown__menu-portal .dropdown__menu-list {
  border: 1px solid #e2e2e2;
  padding: 0;
}

.dropdown__menu .dropdown__menu-list .dropdown__option, .dropdown__menu-portal .dropdown__menu-list .dropdown__option {
  cursor: pointer;
  background-color: #fff;
  height: 40px;
  padding-top: 10px;
}

.dropdown__menu .dropdown__menu-list .dropdown__option.dropdown__option--is-focused, .dropdown__menu .dropdown__menu-list .dropdown__option.dropdown__option--is-selected, .dropdown__menu-portal .dropdown__menu-list .dropdown__option.dropdown__option--is-focused, .dropdown__menu-portal .dropdown__menu-list .dropdown__option.dropdown__option--is-selected {
  color: #000;
  background-color: #f5f5f6;
}

.rc-tooltip.rc-tooltip-zoom-appear, .rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}

.rc-tooltip.rc-tooltip-zoom-enter, .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter, .rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: cubic-bezier(.18, .89, .32, 1.28);
  animation-timing-function: cubic-bezier(.18, .89, .32, 1.28);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-leave {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: cubic-bezier(.6, -.3, .74, .05);
  animation-timing-function: cubic-bezier(.6, -.3, .74, .05);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active, .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
  animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
  animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

.rc-tooltip {
  z-index: 1070;
  visibility: visible;
  opacity: .9;
  background-color: rgba(0, 0, 0, .05);
  padding: 1px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  position: absolute;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-inner {
  color: #333;
  text-align: left;
  background-color: #fff;
  border: 1px solid #b1b1b1;
  border-radius: 3px;
  min-height: 34px;
  padding: 8px 10px;
  text-decoration: none;
}

.rc-tooltip-arrow, .rc-tooltip-arrow-inner {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-width: 6px 6px 0;
  border-top-color: #b1b1b1;
  margin-left: -6px;
  bottom: -5px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow-inner, .rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner, .rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  border-width: 6px 6px 0;
  border-top-color: #fff;
  margin-left: -6px;
  bottom: 1px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-width: 6px 6px 6px 0;
  border-right-color: #b1b1b1;
  margin-top: -6px;
  left: -5px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow-inner, .rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  border-width: 6px 6px 6px 0;
  border-right-color: #fff;
  margin-top: -6px;
  left: 1px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow, .rc-tooltip-placement-leftTop .rc-tooltip-arrow, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-width: 6px 0 6px 6px;
  border-left-color: #b1b1b1;
  margin-top: -6px;
  right: -5px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow-inner, .rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  border-width: 6px 0 6px 6px;
  border-left-color: #fff;
  margin-top: -6px;
  right: 1px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 6px 6px;
  border-bottom-color: #b1b1b1;
  margin-left: -6px;
  top: -5px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  border-width: 0 6px 6px;
  border-bottom-color: #fff;
  margin-left: -6px;
  top: 1px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.help-icon {
  cursor: pointer;
  align-self: center;
}

.help-icon.visible svg path.background, .help-icon:hover svg path.background {
  fill: var(--primary-color);
}

.rc-tooltip {
  opacity: 1 !important;
}

.rc-tooltip.rc-tooltip-placement-bottom {
  border-color: #e8e8e8;
  box-shadow: 0 7px 17px rgba(0, 0, 0, .07);
}

.rc-tooltip .rc-tooltip-inner {
  border-radius: 5px;
  width: 216px;
  padding: 25px 20px;
}

.rc-tooltip .rc-tooltip-inner b {
  font-size: 14px;
}

.rc-tooltip .rc-tooltip-inner ul {
  padding-left: 14px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rc-tooltip.sub-tooltip .rc-tooltip-inner {
  min-width: 300px;
}

.rc-tooltip.sub-tooltip .rc-tooltip-inner > div > div {
  border-bottom: 1px solid #e8e8e8;
}

.rc-tooltip.sub-tooltip .rc-tooltip-arrow {
  display: none;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.export-content .options-content {
  padding-bottom: 20px;
}

.export-content .options-content .cell {
  padding: 16px 16px 14px;
  font-size: 14px;
}

.export-content .options-content .cell.values-row {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.export-content .options-content .results {
  grid-template-columns: 9% 13% 7% 6% 4% 7% 7% 5% 6% 5% 5% 6% 4% 5% 5% 6%;
  margin: 0 auto;
  padding: 0 50px;
  display: grid;
}

.export-content .options-content .results:not(:first-child) .cell.header-row:not(:first-child) {
  display: none;
}

.export-content .options-content .results:first-child .cell.header-row {
  margin-top: 13px;
}

.export-content .options-content .results:first-child .cell.header-row:nth-child(n+10) {
  font-size: 11px;
}

.export-content .options-content .results .cell:not(:first-child):not(:nth-last-child(16)) {
  border-right: 1px solid #e2e2e2;
}

.export-content .options-content .results .cell.header-row {
  margin-top: 20px;
}

.export-content .options-content .results .cell.header-row:first-child {
  color: var(--primary-color);
  align-self: end;
  padding-left: 0;
  font-family: MontserratBold;
  font-size: 22px;
}

.export-content .options-content .results .cell.header-row:not(:first-child), .export-content .options-content .results .cell.header-row:nth-child(2) {
  border-top: 1px solid #e2e2e2;
}

.export-content .options-content .results .cell.header-row:nth-child(2) {
  border-left: 1px solid #e2e2e2;
}

.export-content .options-content .results .cell.full-row, .export-content .options-content .results .cell.full-row b {
  color: #fff;
  background-color: var(--primary-color);
  grid-column: 1 / 17;
  font-size: 20px;
}

.export-content .options-content .results .cell.values-row {
  background-color: #fff;
  height: 18px;
  margin-bottom: 10px;
}

.export-content .options-content .results .cell.header-row:nth-child(2), .export-content .options-content .results .cell.values-row:nth-last-child(15) {
  font-family: MontserratBold;
  font-size: 16px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.export-content .options-content .ra-details {
  margin: auto;
  padding: 0 50px;
  left: 0;
  right: 0;
}

.export-content .options-content .ra-details .column-titles, .export-content .options-content .ra-details .main-values {
  grid-template-columns: minmax(15%, 1fr) minmax(9%, 1fr) minmax(8%, 1fr) minmax(5%, 1fr) minmax(8%, 1fr) minmax(5%, 1fr) minmax(9%, 1fr) minmax(11%, 1fr) minmax(1%, 1fr) minmax(12%, 1fr) minmax(10%, 1fr) minmax(2%, 1fr);
  display: grid;
}

.export-content .options-content .ra-details .column-titles > .cell {
  font-size: 13px;
}

.export-content .options-content .ra-details .main-values {
  background-color: #fff;
  box-shadow: 0 7px 17px rgba(0, 0, 0, .07);
}

.export-content .options-content .ra-details .main-values .cell:first-child {
  font-family: MontserratBold;
}

.export-content .options-content .ra-details .secondary-values {
  background-color: #ebeced;
  grid-template-columns: repeat(4, 25%);
  margin-bottom: 20px;
  padding: 12px 17px;
  display: grid;
}

.export-content .options-content .ra-details .secondary-values > .cell {
  padding: 0;
  font-size: 13px;
}

.export-content .options-content .ra-details .secondary-values > .cell b {
  margin-right: 10px;
  font-family: MontserratBold;
}

.export-content .options-content .ra-details .cell {
  white-space: nowrap;
  justify-self: center;
  overflow: hidden;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.export-content {
  width: 1684px;
  position: relative;
}

.export-content .header {
  background-color: var(--primary-color);
  width: 1684px;
  height: 22px;
  padding: 16px 20px 15px;
}

.export-content .section-header {
  color: #fff;
  opacity: .75;
  background-color: #000;
  align-items: center;
  width: 1568px;
  height: 30px;
  margin: 20px auto 12px;
  padding: 0 8px;
  display: flex;
}

.export-content .requirements {
  grid-template-columns: 1fr 1fr 1fr;
  width: 1584px;
  margin: 0 auto;
  display: grid;
}

.export-content .requirements .column {
  border-radius: 10px;
}

.export-content .requirements .column:not(:first-child) {
  margin-left: 14px;
}

.export-content .requirements .column .column-title {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px 10px 0 0;
  align-items: center;
  height: 40px;
  padding: 0 17px;
  font-size: 12px;
  display: flex;
}

.export-content .requirements .column .column-content {
  background-color: #ececee;
  border-radius: 0 0 10px 10px;
}

.export-content .requirements .column .column-content .column-content-row {
  border: 1px solid #dcdcdc;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 17px;
  display: flex;
}

.export-content .requirements .column .column-content .column-content-row .bold {
  font-weight: bold;
}

.export-content .requirements .column .column-content .column-content-row:last-child {
  border-radius: 0 0 10px 10px;
}

.export-content .requirements .column .column-content .column-content-row > div {
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  display: grid;
}

.export-content .requirements .column .column-content .column-content-row > div.largeWidth {
  grid-template-columns: 1fr 60px;
}

.export-content .requirements .column .column-content .column-content-row > div.smallWidth {
  grid-template-columns: 1fr 30px;
}

.export-content .requirements .column .column-content .column-content-row > div > div:first-child {
  background-color: rgba(0, 0, 0, .1);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 28px;
  display: flex;
}

.export-content .requirements .column .column-content .column-content-row > div > div:last-child {
  margin-left: 15px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader {
  width: 108px;
  margin-top: 278px;
  position: fixed;
  right: 39%;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  z-index: 10;
}

.ReactModal__Overlay .ReactModal__Content {
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ReactModal__Overlay .ReactModal__Content .modal-header {
  background-color: var(--primary-color);
  color: #fff;
  width: 660px;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: bold;
}

.ReactModal__Overlay .ReactModal__Content .modal-content {
  padding: 20px 30px;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .comment {
  width: 660px;
  margin-bottom: 20px;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .content {
  max-height: 280px;
  overflow-y: scroll;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .row {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .row .dropdown, .ReactModal__Overlay .ReactModal__Content .modal-content .row input {
  width: 300px;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .modal-footer {
  border-top: 1px solid #000;
  justify-content: flex-end;
  margin-top: 30px;
  padding: 20px 0 0;
  display: flex;
}

.ReactModal__Overlay .ReactModal__Content .modal-content .modal-footer button {
  margin-left: 10px;
}

.ReactModal__Overlay .ReactModal__Content#export-file-name-modal .loader {
  margin-top: -125px;
  -webkit-animation: 6s linear infinite spin;
  animation: 6s linear infinite spin;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.threshold-tooltip.rc-tooltip .rc-tooltip-inner {
  padding: 0;
}

.threshold-tooltip.rc-tooltip .rc-tooltip-inner div {
  justify-content: space-between;
  padding: 15px 20px;
  display: flex;
}

.threshold-tooltip.rc-tooltip .rc-tooltip-inner div.warning-part {
  border-bottom: 1px solid #e8e8e8;
  font-size: 11px;
  font-weight: bold;
}

.threshold-tooltip.rc-tooltip .rc-tooltip-inner div.warning-part .exclamationMark {
  text-align: center;
  color: #fff;
  background-color: #dd2524;
  border-radius: 10px;
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 22px;
  padding-left: 32px;
  line-height: 22px;
  display: block;
  position: relative;
}

.container input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.container input[type="checkbox"]:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.container input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.container input[type="checkbox"]:not(:checked) ~ .checkmark:after {
  display: none;
}

.container .checkmark {
  background-color: #f5f5f6;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.container .checkmark:after {
  content: "";
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 4px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.results-grid {
  padding: 0 35px;
  position: relative;
}

.results-grid .results-row {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  height: 60px;
  margin-bottom: 12px;
}

.results-grid .results-row.selected {
  background-color: rgba(232, 234, 238, .5);
}

.results-grid .results-row.selected > div:nth-child(2) {
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.results-grid .results-row > div {
  align-self: center;
  display: flex;
  position: relative;
}

.results-grid .results-row > div:first-child {
  justify-content: center;
}

.results-grid .results-row > div:first-child label {
  padding-left: 22px;
}

.results-grid .results-row > div .bom-button {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: #fff;
  border-radius: 12px;
  width: 46px;
  height: 24px;
  padding: 0;
  font-size: 12px;
}

.results-grid .results-row > div .bom-button:hover {
  background-color: rgba(108, 7, 224, .1);
}

.results-grid .results-row > div .help-icon {
  margin-left: 8px;
}

.results-grid .results-row > div .red {
  color: #dd2524;
  cursor: pointer;
  text-decoration: underline;
}

.results-grid > svg {
  position: absolute;
  top: 50px;
  left: 25%;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner, .sub-tooltip.rc-tooltip .rc-tooltip-inner {
  width: 255px;
  padding: 0;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div {
  justify-content: space-between;
  padding: 15px 20px;
  display: flex;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div b, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div b {
  font-size: 12px;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.priceCell, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.priceCell {
  width: 80px;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.priceCell:first-child, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.priceCell:first-child {
  width: 150px;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.filesAndDirsCell, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div > div.filesAndDirsCell {
  text-align: center;
  width: 110px;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner > div > div.selected, .sub-tooltip.rc-tooltip .rc-tooltip-inner > div > div.selected {
  background-color: rgba(245, 245, 246, .7);
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue, .sub-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue {
  display: flex;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue > div, .sub-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue > div {
  color: #6d7278;
  margin-right: 5px;
}

.result-help-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue > div.red, .sub-tooltip.rc-tooltip .rc-tooltip-inner .tooltipValue > div.red {
  color: #dd2524;
}

.result-help-tooltip.rc-tooltip.performance .rc-tooltip-inner, .sub-tooltip.rc-tooltip.performance .rc-tooltip-inner {
  width: 200px;
}

.result-help-tooltip.rc-tooltip.performance .rc-tooltip-arrow, .sub-tooltip.rc-tooltip.performance .rc-tooltip-arrow {
  left: 90%;
}

.result-help-tooltip.rc-tooltip:not(.sub-tooltip.rc-tooltip) .rc-tooltip-inner > div > div:first-child, .sub-tooltip.rc-tooltip:not(.sub-tooltip.rc-tooltip) .rc-tooltip-inner > div > div:first-child {
  color: #6d7278;
  padding-bottom: 0;
  font-size: 11px;
}

.result-help-tooltip.rc-tooltip:not(.sub-tooltip.rc-tooltip) .rc-tooltip-inner > div > div:not(:first-child), .sub-tooltip.rc-tooltip:not(.sub-tooltip.rc-tooltip) .rc-tooltip-inner > div > div:not(:first-child) {
  border-bottom: 1px solid #e8e8e8;
}

.result-help-tooltip.rc-tooltip.big .rc-tooltip-inner, .sub-tooltip.rc-tooltip.big .rc-tooltip-inner {
  width: 600px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.results-pane {
  width: calc(100% - 342px);
}

.results-pane .results-counter {
  z-index: 1;
  background-color: #f5f5f6;
  height: 36px;
  padding: 34px 35px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.results-pane .results-counter .counter {
  color: #6d7278;
}

.results-pane .results-counter {
  z-index: 3;
  justify-content: space-between;
  display: flex;
}

.results-pane .export-row {
  padding: 0 35px;
  display: flex;
}

.results-pane .export-row .export-dropdown-holder {
  width: 100px;
}

.results-pane .export-row .export-dropdown {
  z-index: 2;
}

.results-pane .export-row .clear-export {
  cursor: pointer;
  color: #6d7278;
  z-index: 3;
  align-self: center;
  margin-left: -64px;
}

.results-pane .export-row .clear-export:hover {
  text-decoration: underline;
}

.results-pane .results-row {
  grid-template-columns: 50px 130px minmax(150px, 1fr) minmax(72px, 1fr) 100px minmax(88px, 1fr) 1fr 1fr minmax(140px, 1fr) minmax(140px, 1fr) 80px;
  display: grid;
}

.results-pane .results-row.for-partner {
  grid-template-columns: 50px 130px minmax(150px, 1fr) minmax(72px, 1fr) 100px minmax(88px, 1fr) 1fr 1fr minmax(140px, 1fr) 80px;
}

.results-pane .results-row.results-header {
  z-index: 1;
  background-color: #f5f5f6;
  margin: 20px 35px 0;
  padding-bottom: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
}

.results-pane .results-row.results-header .servers-enclosures {
  margin-left: 30px;
  margin-right: 40px;
}

.results-pane .results-row > .center {
  justify-self: center;
}

.results-pane .results-row .copy {
  cursor: pointer;
}

.results-pane .error {
  color: red;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 40px;
  font-size: 18px;
  display: flex;
}

.results-pane .error > div {
  margin-top: 10px;
}

.results-pane.calculating {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  opacity: .5;
  pointer-events: none;
}

.export-dropdown-tooltip.rc-tooltip .rc-tooltip-inner {
  color: #6d7278;
  width: auto;
  min-height: auto;
  padding: 14px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.radio {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.radio input {
  display: none;
}

.radio input:checked ~ .radio-background {
  transition: -webkit-transform .5s ease-in-out, transform .5s ease-in-out;
}

.radio input:checked + label {
  color: #fff;
}

.radio input:checked:first-of-type ~ .radio-background {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.radio input:checked:nth-child(3) ~ .radio-background {
  -webkit-transform: translateX(84px);
  transform: translateX(84px);
}

.radio input:checked:nth-child(5) ~ .radio-background {
  -webkit-transform: translateX(113px);
  transform: translateX(113px);
}

.radio .radio-background, .radio .label {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: 1px solid #e2e2e2;
  width: 84px;
  height: 22px;
  padding-top: 8px;
  display: inline-block;
}

.radio.with-3 .radio-background, .radio.with-3 .label {
  width: 56px;
}

.radio.with-3 input:checked:nth-child(3) ~ .radio-background {
  -webkit-transform: translateX(56px);
  transform: translateX(56px);
}

.radio .radio-background {
  background-color: var(--primary-color);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.radio .radio-element {
  grid-gap: 10px;
  cursor: pointer;
  grid-template-columns: min-content auto;
  align-items: baseline;
  margin-top: 10px;
  display: grid;
}

.radio .radio-element .radio-input {
  display: inherit;
  align-items: center;
}

.radio .radio-element .radio-input input {
  display: none;
}

.radio .radio-element .radio-input input + .radio-control:before {
  content: "";
  box-shadow: inset 9px 9px var(--primary-color);
  border-radius: 50%;
  width: 9px;
  height: 9px;
  transition: -webkit-transform .18s ease-in-out, transform .18s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio .radio-element .radio-input input:checked + .radio-control:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radio .radio-element .radio-input .radio-control {
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  align-items: center;
  justify-items: center;
  width: 21px;
  height: 21px;
  display: grid;
}

.radio .radio-element.disabled .radio-input input + .radio-control:before {
  box-shadow: inset 9px 9px #e2e2e2;
}

.radio .radio-element.disabled b {
  opacity: .2;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.requirements-pane .requirement-row.customised {
  cursor: pointer;
  flex-direction: column;
  width: 100%;
  height: 32px;
}

.requirements-pane .requirement-row.customised .customised-title {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.requirements-pane .requirement-row.customised .customised-title span {
  font-weight: normal;
}

.requirements-pane .requirement-row.customised .customised-content {
  visibility: hidden;
  opacity: 0;
  margin-top: 8px;
}

.requirements-pane .requirement-row.customised svg {
  transition-property: -webkit-transform, transform;
  transition-duration: 1s;
  overflow: hidden;
}

.requirements-pane .requirement-row.customised .requirement-row {
  width: 100%;
}

.requirements-pane .requirement-row.customised .requirement-row b {
  width: 94px;
  margin-right: 6px;
}

.requirements-pane .requirement-row.customised .requirement-row .radio {
  margin-right: 19px;
}

.requirements-pane .requirement-row.customised .requirement-row > input:last-child {
  width: 182px;
}

.requirements-pane .requirement-row.customised.open {
  height: 100%;
}

.requirements-pane .requirement-row.customised.open .customised-title svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.requirements-pane .requirement-row.customised.open .customised-content {
  visibility: visible;
  opacity: 1;
  transition: visibility, opacity 1s ease-out;
}

.requirements-pane .requirement-row.customised.open .requirement-row {
  justify-content: space-between;
}

.requirements-pane .requirement-row.customised.open .requirement-row.reset {
  color: #6d7278;
  justify-content: space-between;
  margin-top: 20px;
  display: none;
}

.requirements-pane .requirement-row.customised.open .requirement-row.reset.changed {
  display: flex;
}

.requirements-pane .requirement-row.customised.open .requirement-row.reset svg {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.system-capacity .tabs-container > div:last-child {
  flex: 1;
  width: 100%;
}

.system-capacity .requirement-box-title {
  padding: 24px 24px 24px 30px;
}

.system-capacity .requirement-box.first-tab .requirement-row:first-child {
  margin-top: 0;
}

.system-capacity .requirement-box.first-tab .toggle, .system-capacity .requirement-box.first-tab .cold-capacity {
  margin-top: 16px;
}

.system-capacity .requirement-box.first-tab .requirement-row.cold-capacity b {
  margin-right: 16px;
}

.system-capacity .requirement-box.second-tab .toggle div:first-child {
  margin-right: 36px;
}

.system-capacity .requirement-box.second-tab .number-of-enclosures input {
  width: 182px;
}

.system-capacity .requirement-box.second-tab .cold-capacity b {
  margin-right: 10px;
}

.system-capacity .requirement-box.second-tab .customize-ra {
  margin-top: 16px;
}

.system-capacity .dropdown {
  margin-left: auto;
}

.system-capacity .tab.by-sizing {
  width: 99px;
  padding-left: 50px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.requirements-pane .advanced {
  cursor: pointer;
  border-top: 1px solid #e8e8e8;
  height: 8px;
  margin-top: 28px;
  padding-top: 16px;
}

.requirements-pane .advanced .advanced-title {
  align-items: center;
  font-size: 14px;
}

.requirements-pane .advanced svg {
  transition-property: -webkit-transform, transform;
  transition-duration: 1s;
  overflow: hidden;
}

.requirements-pane .advanced .requirement-row {
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity 2s linear;
}

.requirements-pane .advanced .requirement-row .toggle {
  margin-right: 88px;
}

.requirements-pane .advanced .requirement-row input.stripe {
  margin-left: 15px;
}

.requirements-pane .advanced .requirement-row .radio {
  margin-right: 6px;
}

.requirements-pane .advanced .requirement-row .hotspare {
  width: 175px;
}

.requirements-pane .advanced .requirement-row .memory {
  margin-left: 25px;
}

.requirements-pane .advanced .requirement-row .frontend-cores {
  margin-right: 69px;
}

.requirements-pane .advanced .requirement-row b + input {
  margin-left: 8px;
  margin-right: 0;
}

.requirements-pane .advanced .requirement-row input + b {
  margin-right: 30px;
}

.requirements-pane .advanced .requirement-row svg {
  vertical-align: super;
}

.requirements-pane .advanced.open {
  height: 100%;
}

.requirements-pane .advanced.open .advanced-title svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.requirements-pane .advanced.open .requirement-row {
  visibility: visible;
  opacity: 1;
  justify-content: space-between;
}

.requirements-pane .advanced.open .requirement-row.reset {
  color: #6d7278;
  justify-content: space-between;
  display: none;
}

.requirements-pane .advanced.open .requirement-row.reset.changed {
  display: flex;
}

.requirements-pane .advanced.open .requirement-row.reset svg {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.radio-input-component .radio-element {
  grid-gap: 10px;
  cursor: pointer;
  grid-template-columns: min-content auto;
  margin-top: 10px;
  display: grid;
}

.radio-input-component .radio-element .radio-input {
  display: inherit;
  align-items: center;
}

.radio-input-component .radio-element .radio-input input {
  display: none;
}

.radio-input-component .radio-element .radio-input input + .radio-control:before {
  content: "";
  box-shadow: inset 9px 9px var(--primary-color);
  border-radius: 50%;
  width: 9px;
  height: 9px;
  transition: -webkit-transform .18s ease-in-out, transform .18s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-input-component .radio-element .radio-input input:checked + .radio-control:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radio-input-component .radio-element .radio-input .radio-control {
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  align-items: center;
  justify-items: center;
  width: 21px;
  height: 21px;
  display: grid;
}

.radio-input-component .radio-element .radio-label {
  -webkit-user-select: none;
  user-select: none;
}

.radio-input-component .radio-element .radio-label.disabled input, .radio-input-component .radio-element .radio-label.disabled > b {
  opacity: .2;
}

.radio-input-component .radio-element .radio-label div {
  min-width: 53px;
  display: inline-block;
}

.radio-input-component .radio-element .radio-label > b {
  margin-left: 18px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.performance .rc-tooltip-inner {
  width: 238px;
}

.performance .requirement-row {
  margin-top: 24px;
}

.performance .requirement-row.performance-type {
  margin-top: 10px;
}

.performance .requirement-row.iops, .performance .requirement-row.bw {
  margin-top: 8px;
}

.performance .requirement-row.rw b {
  margin-right: 40px;
}

.performance .requirement-row .radio-label {
  margin-left: 4px;
  margin-right: 38px;
}

.performance .requirement-row div {
  min-width: 53px;
}

.performance .io-size .radio-background, .performance .io-size-by-sizing .radio-background {
  min-width: 36px;
}

.performance .io-size .radio *, .performance .io-size-by-sizing .radio * {
  width: 36px;
}

.performance .io-size .radio input:checked:nth-child(3) ~ .radio-background, .performance .io-size-by-sizing .radio input:checked:nth-child(3) ~ .radio-background {
  -webkit-transform: translateX(36px);
  transform: translateX(36px);
}

.performance .io-size {
  margin-left: 76px;
  display: flex;
  position: relative;
}

.performance .io-size .radio-input-component .radio-input .radio-control {
  display: none;
}

.performance .io-size .radio {
  align-self: center;
  margin-top: 10px;
  position: absolute;
  right: -4px;
}

.performance .io-size .radio-element .radio-label input {
  width: 74px;
}

.performance .io-size .radio-element .radio-label div {
  min-width: 46px;
}

.performance .io-size.disabled {
  pointer-events: none;
  opacity: .2;
}

.performance .io-size b {
  font-size: 11px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.customer-and-deal.requirement-box .requirement-row {
  justify-content: space-between;
  margin-top: 24px;
}

.customer-and-deal.requirement-box .requirement-row .radio {
  margin-right: 24px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.requirements-pane .output-control.requirement-box .requirement-row {
  margin-top: 20px;
}

.requirements-pane .output-control.requirement-box .requirement-row .radio {
  margin-left: 8px;
}

.requirements-pane .output-control.requirement-box .vendors-title {
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

.requirements-pane .output-control.requirement-box .vendor-container {
  border: 1px solid #e2e2e2;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  display: flex;
}

.requirements-pane .output-control.requirement-box .vendor-container:not(:last-child) {
  border-bottom: none;
}

.requirements-pane .output-control.requirement-box .vendor-container button {
  opacity: .7;
  border: 1px solid #e2e2e2;
  height: 30px;
  padding: 0 10px;
  font-size: 11px;
  font-weight: normal;
  display: none;
}

.requirements-pane .output-control.requirement-box .vendor-container:hover {
  background-color: #f5f5f6;
}

.requirements-pane .output-control.requirement-box .vendor-container:hover button {
  display: block;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.requirements-pane {
  background-color: #fff;
  width: 342px;
  height: 100%;
}

.requirements-pane .header {
  background-color: var(--primary-color);
  color: #fff;
  z-index: 5;
  padding: 17px 25px 17px 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.requirements-pane .requirement-box {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin: 7px;
  padding: 17px 16px 25px 22px;
}

.requirements-pane .requirement-box .requirement-row {
  align-items: center;
  margin-top: 24px;
  display: flex;
  position: relative;
}

.requirements-pane .requirement-box .requirement-row b, .requirements-pane .requirement-box .requirement-row input.small {
  margin-right: 21px;
}

.requirements-pane .requirement-box .requirement-row .for-radio {
  width: 94px;
  margin-right: 0;
}

.requirements-pane .requirement-box .requirement-row .help-icon {
  position: absolute;
  bottom: 6px;
  right: 0;
}

.requirements-pane .requirement-box-title, .requirements-pane .advanced-title {
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.requirements-pane.disabled {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  opacity: .5;
  pointer-events: none;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header {
  background-color: #fff;
  padding-bottom: 37px;
}

.header a {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.header .top-line {
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  display: flex;
}

.header .top-line .documentation-link {
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
  font-weight: bold;
}

.header .top-line .documentation-link:hover {
  background-color: var(--primary-color-hover);
}

.header .centered {
  cursor: default;
  text-align: center;
}

.header .centered .welcome-message {
  font-weight: bold;
}

.header .centered .welcome-message a {
  color: #0073cf;
}

.header .centered .title {
  margin-top: 10px;
  font-size: 38px;
  font-weight: bold;
}

.header .centered .title .colored {
  color: var(--primary-color);
}

.header .centered .sub-title {
  margin-top: 8px;
  font-size: 20px;
}

.header .centered p {
  color: rgba(0, 0, 0, .5);
  margin: 0;
}

.header .centered p:first-of-type {
  margin-top: 20px;
}

.header .centered p .emphasized {
  color: #000;
  font-weight: bold;
}

.header .centered p .emphasized.documentation a:hover {
  text-decoration: underline;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.sizer-calculator .content {
  display: flex;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notion-database-validation .response {
  color: #36c7a0;
  margin: 20px;
  font-weight: 600;
}

.notion-database-validation .response.has-errors {
  color: #6d7278;
}

.notion-database-validation .errors li {
  color: #dd2524;
  margin-left: 40px;
  list-style: square;
}

.notion-database-validation .loader {
  position: inherit;
  margin-top: 0;
}

.notion-database-validation .button {
  margin-left: 20px;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notion-data-copy {
  padding: 20px;
  font-size: 16px;
}

.notion-data-copy .action-row {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.notion-data-copy .action-row .dropdown {
  margin: 0 15px;
}

.notion-data-copy .status {
  color: #36c7a0;
  margin: 20px;
  font-weight: 600;
}

.notion-data-copy .status.has-errors {
  color: #6d7278;
}

.notion-data-copy .errors {
  margin-top: 20px;
}

.notion-data-copy .errors li {
  color: #dd2524;
  margin-left: 40px;
  list-style: square;
}

.notion-data-copy .loader {
  position: inherit;
  margin-top: 0;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.sizer-admin .header {
  color: var(--primary-color);
  border-bottom: 1px solid #000;
  padding: 20px;
}

.sizer-admin .content {
  height: 100%;
  padding: 20px;
}

.sizer-admin .content .users-table {
  grid-template-columns: minmax(20%, 300px) 1fr 4%;
  row-gap: 10px;
  width: 80%;
  max-height: 800px;
  margin: 50px auto;
  display: grid;
  overflow-y: scroll;
}

.sizer-admin .content .users-table div {
  align-self: center;
}

.sizer-admin .content .users-table div input {
  text-align: left;
}

.sizer-admin .content .users-table .actions {
  justify-self: center;
}

.sizer-admin .content .users-table .actions svg {
  cursor: pointer;
}

:root {
  --primary-color: #6e06df;
  --primary-color-hover: #8126e2;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notification-container--top-center, .notification-container--top-left, .notification-container--top-right, .notification-container--bottom-center, .notification-container--bottom-left, .notification-container--bottom-right, .notification-container--center, .notification-container--top-full, .notification-container--bottom-full {
  pointer-events: all;
  min-width: 325px;
  position: absolute;
}

.notification-container--center, .notification-container--top-center, .notification-container--bottom-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  display: flex;
  left: calc(50% - 175px);
}

.notification-container--center {
  pointer-events: none;
  height: 100%;
  top: 20px;
}

.notification-container--top-full, .notification-container--bottom-full {
  width: 100%;
  min-width: 100%;
}

.notification-container--bottom-full {
  bottom: 0;
}

.flex-center {
  pointer-events: all;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 325px;
  display: flex;
}

.notification-container--top-center {
  top: 20px;
}

.notification-container--bottom-center {
  bottom: 20px;
}

.notification-container--top-left {
  top: 20px;
  left: 20px;
}

.notification-container--top-right {
  top: 20px;
  right: 20px;
}

.notification-container--bottom-left {
  bottom: 20px;
  left: 20px;
}

.notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}

.notification-container--mobile-top, .notification-container--mobile-bottom {
  pointer-events: all;
  position: absolute;
}

.notification-container--mobile-top {
  top: 20px;
  left: 20px;
  right: 20px;
}

.notification-container--mobile-bottom {
  margin-bottom: -15px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.notification__item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;
}

.notification__item--default .notification__timer {
  background-color: #007bff;
}

.notification__item--default .notification__timer-filler {
  background-color: #fff;
}

.notification__item--default .notification__close {
  background-color: #007bff;
}

.notification__item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
}

.notification__item--success .notification__timer {
  background-color: #28a745;
}

.notification__item--success .notification__timer-filler {
  background-color: #fff;
}

.notification__item--success .notification__close {
  background-color: #28a745;
}

.notification__item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;
}

.notification__item--danger .notification__timer {
  background-color: #dc3545;
}

.notification__item--danger .notification__timer-filler {
  background-color: #fff;
}

.notification__item--danger .notification__close {
  background-color: #dc3545;
}

.notification__item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;
}

.notification__item--info .notification__timer {
  background-color: #17a2b8;
}

.notification__item--info .notification__timer-filler {
  background-color: #fff;
}

.notification__item--info .notification__close {
  background-color: #17a2b8;
}

.notification__item--warning {
  background-color: #eab000;
  border-left: 8px solid #ce9c09;
}

.notification__item--warning .notification__timer {
  background-color: #eab000;
}

.notification__item--warning .notification__timer-filler {
  background-color: #fff;
}

.notification__item--warning .notification__close {
  background-color: #eab000;
}

.notification__item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}

.notification__item--awesome .notification__timer {
  background-color: #685dc3;
}

.notification__item--awesome .notification__timer-filler {
  background-color: #fff;
}

.notification__item--awesome .notification__close {
  background-color: #685dc3;
}

@-webkit-keyframes timer {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes timer {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.notifications-component {
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
}

.notification__item {
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 15px;
  display: flex;
  position: relative;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, .2);
}

.notification-container--top-full .notification__item, .notification-container--bottom-full .notification__item {
  border-radius: 0;
  margin-bottom: 0;
}

.notification__timer {
  border-radius: 5px;
  width: 100%;
  height: 3px;
  margin-top: 10px;
}

.notification__timer-filler {
  border-radius: 5px;
  height: 3px;
}

.notification__title {
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}

.notification__message {
  color: #fff;
  word-wrap: break-word;
  max-width: calc(100% - 15px);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 150%;
}

.notification__content {
  width: 100%;
  padding: 8px 15px;
  display: inline-block;
}

.notification__close {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification__close:after {
  content: "×";
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notification-container--mobile-top .notification__item, .notification-container--mobile-bottom .notification__item, .notification-container--mobile-top .notification, .notification-container--mobile-bottom .notification {
  width: 100%;
  max-width: 100%;
}

.notification-container--top-right .notification, .notification-container--bottom-right .notification {
  margin-left: auto;
}

.notification-container--top-left .notification, .notification-container--bottom-left .notification {
  margin-right: auto;
}

.notification-container--mobile-top .notification, .notification-container--mobile-bottom .notification {
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.1c26291d.ttf") format("truetype");
}

@font-face {
  font-family: MontserratBold;
  src: url("Montserrat-Bold.0f16d814.ttf") format("truetype");
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f6;
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*# sourceMappingURL=index.fb31a70f.css.map */
