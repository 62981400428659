@import './variables.scss';

.ReactModal__Overlay {
  z-index: 10;
  .ReactModal__Content {
    margin-right: -50%;
    transform: translate(-50%, -50%);
    .modal-header {
      width: 660px;
      background-color: var(--primary-color);
      color: white;
      padding: 20px 30px;
      font-weight: bold;
      font-size: 16px;
    }
    .modal-content {
      padding: 20px 30px;
      .comment {
        width: 660px;
        margin-bottom: 20px;
      }
      .content {
        max-height: 280px;
        overflow-y: scroll;
      }
      .row {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .dropdown, input {
          width: 300px;
        }
      }
      .modal-footer {
        border-top: 1px solid black;
        margin-top: 30px;
        padding: 20px 0px 0px;
        display: flex;
        justify-content: flex-end;
        button {
          margin-left: 10px;
        }
      }
    }

    &#export-file-name-modal .loader {
      margin-top: -125px;
      -webkit-animation:spin 6s linear infinite;
      -moz-animation:spin 6s linear infinite;
      animation:spin 6s linear infinite;
    }
  }
}