@import '../../styles/variables';

.input {
  height: $component-height;
  color: $input-color;
  border: $border;
  border-radius: $border-radius;
  text-align: center;
  font-size: 16px;
  outline: none;
  &.small {
    width: $component-small-width;
  }
  &.medium {
    width: $component-medium-width;
  }
  &.tag {
    width: 56px;
    height: 21px;
    color: rgba(0,0,0,0.85);
    font-size: 12px;
    background-color: transparent;
    border-radius: 11px;
    border: 1px solid rgba(0,0,0,0.25);
    &::placeholder {
      color: black;
      opacity: 0.85;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
}