@import '/src/storybook/styles/variables';

.requirements-pane .requirement-row.customised {
  height: 32px;
  flex-direction: column;
  cursor: pointer;
  width: 100% ;
  .customised-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      font-weight: normal;
    }
  }
  .customised-content {
    margin-top: 8px;
    visibility: hidden;
    opacity: 0;
  }
  svg {
    overflow: hidden;
    transition-duration: 1s;
    transition-property: transform;
  }
  .requirement-row {
    width: 100%;
    b {
      width: 94px;
      margin-right: 6px;
    }
    .radio {
      margin-right: 19px;
    }
    > input:last-child {
      width: 182px;
    }
  }
  &.open {
    height: 100%;
    .customised-title svg {
      transform: rotate(180deg);
    }
    .customised-content {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 1s ease-out;
    }
    .requirement-row {
      justify-content: space-between;
      &.reset {
        margin-top: 20px;
        justify-content: space-between;
        color: $secondary-text-color;
        display: none;
        &.changed {
          display: flex;
        }
        svg {
          margin-right: 4px;
          vertical-align: middle;
          margin-top: -2px;
          transform: rotate(360deg);
        }  
      }
    }
  }
}