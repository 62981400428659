@import '/src/storybook/styles/variables';
@import '/src/storybook/styles/mixins';

.requirements-pane {
  width: $requirements-pane-width;
  background-color: white;
  height: 100%;
  .header {
    background-color: var(--primary-color);
    color: white;
    padding: $requirements-padding;
    position: sticky;
    top: 0px;
    z-index: 5;
  }

  .requirement-box {
    border: 1px solid $box-border-color;
    margin: 7px;
    border-radius: 10px;
    padding: 17px 16px 25px 22px;
    .requirement-row {
      position: relative;
      margin-top: 24px;
      display: flex;
      align-items: center;
      b, input.small {
        margin-right: 21px;
      }
      .for-radio {
        width: 94px;
        margin-right: 0px
      }
      .help-icon {
        position: absolute;
        right: 0px;
        bottom: 6px;
      }
    }
  }

  .requirement-box-title, .advanced-title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }

  &.disabled {
    @include disabled-component;
  }
}
