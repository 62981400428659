@import '/src/storybook/styles/variables';

.requirements-pane .advanced {
  height: 8px;
  cursor: pointer;
  border-top: 1px solid $box-border-color;
  margin-top: 28px;
  padding-top: 16px;
  .advanced-title {
    font-size: 14px;
    align-items: center;
  }
  svg {
    overflow: hidden;
    transition-duration: 1s;
    transition-property: transform;
  }
  .requirement-row {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 2s linear;
    .toggle {
      margin-right: 88px;
    }
    input.stripe {
      margin-left: 15px;
    }
    .radio {
      margin-right: 6px;
    }
    .hotspare {
      width: 175px;
    }
    .memory {
      margin-left: 25px;
    }
    .frontend-cores {
      margin-right: 69px;
    }
    b + input {
      margin-right: 0px;
      margin-left: 8px;
    }
    input + b {
      margin-right: 30px;
    }
    svg {
      vertical-align: super;
    }
  }
  &.open {
    height: 100%;
    .advanced-title svg {
      transform: rotate(180deg);
    }
    .requirement-row {
      justify-content: space-between;
      visibility: visible;
      opacity: 1;
      &.reset {
        justify-content: space-between;
        color: $secondary-text-color;
        display: none;
        &.changed {
          display: flex;
        }
        svg {
          margin-right: 4px;
          vertical-align: middle;
          margin-top: -2px;
          transform: rotate(360deg);
        }
      }
    }
  }
}
