@import '../../styles/variables';

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  .tab {
    height: 15px;
    flex: 0%;
    padding: 22px 20px 22px 30px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    &:first-child {
      border-right: $border;
    }

    &:not(:first-child) {
      padding: 22px 0px;
    }

    &:not(.selected) {
      border-bottom: $border;
    }

    &.selected {
      color: var(--primary-color);
      border-top: $border;
    }
  }
}