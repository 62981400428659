@import '/src/storybook/styles/variables';

.export-content .options-content .ra-details {
  padding: 0px 50px;
  margin: auto;
  left: 0px;
  right: 0px;

  .column-titles, .main-values {
    display: grid;
    grid-template-columns: minmax(15%, 1fr) minmax(9%, 1fr) minmax(8%, 1fr) minmax(5%, 1fr) minmax(8%, 1fr) minmax(5%, 1fr) minmax(9%, 1fr) minmax(11%, 1fr) minmax(1%, 1fr) minmax(12%, 1fr) minmax(10%, 1fr) minmax(2%, 1fr);
  }
  .column-titles > .cell {
    font-size: 13px;
  }
  .main-values {
    background-color: white;
    box-shadow: $shadow;
    .cell:first-child {
      font-family: 'MontserratBold';
    }
  }
  .secondary-values {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    padding: 12px 17px;
    background-color: $secondary-background-color;
    margin-bottom: 20px;
    > .cell {
      padding: 0px;
      font-size: 13px;
      b {
        margin-right: 10px;
        font-family: 'MontserratBold';
      }
    }
  }
  .cell {
    justify-self: center;
    overflow: hidden;
    white-space: nowrap;
  }
}