@import '/src/storybook/styles/variables';

.notion-database-validation {
  .response {
    margin: 20px;
    color: $success-color;
    font-weight: 600;
    &.has-errors {
      color: $secondary-text-color;
    }
  }
  .errors {
    li {
      color: $error-color;
      list-style: square;
      margin-left: 40px;
    }
  }
  .loader {
    position: inherit;
    margin-top: 0px;
  }
  .button {
    margin-left: 20px;
  }
}