@import '../../styles/variables';

.dropdown {
  width: $component-medium-width;
  user-select: none;
  cursor: pointer;
  line-height: 1.1;
  > div {
    height: $component-height;
    border-radius: 2px;
    box-shadow: none;
    cursor: pointer;
    &, &:hover {
      border-color: $border-color;
    }
    span {
      display: none;
    }
    svg path {
      fill: black;
    }
  }
  .dropdown__control--menu-is-open svg {
    transform: rotate(180deg);
    transition: 1s ease-in-out;
  }
  .dropdown__single-value {
    color: black;
  }
}

.export-dropdown {
  cursor: auto;
  .dropdown__control {
    border-radius: 4px;
    width: 100px;
    & > div > div {
      margin-left: 8px;
    }
    .dropdown__indicators {
      width: 0;
      height: 0;
      margin: 16px 15px 15px 0px;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: black transparent transparent transparent;
      svg {
        display: none;
      }
    }
    &.dropdown__control--menu-is-open .dropdown__indicators {
      border-width: 0 4px 5px 4px;
      border-color: transparent transparent black transparent;
    }
  }
  .dropdown__menu {
    margin-top: 4px;
    .dropdown__menu-list {
      background-color: white;
      border-radius: 4px;
      padding: 2px;
      overflow-y: initial;
    }
    .dropdown__option {
      padding-left: 6px;
      border-radius: 2px;
      & > div {
        display: flex;
        font-size: 12px;
        & > span {
          display: block;
          padding-top: 4px;
        }
      }
    }
  }
}

.dropdown__menu, .dropdown__menu-portal {
  margin-top: -1px;
  .dropdown__menu-list {
    border: 1px solid $border-color;
    padding: 0px;
    .dropdown__option {
      cursor: pointer;
      padding-top: 10px;
      height: $component-height;
      background-color: white;
      &.dropdown__option--is-focused, &.dropdown__option--is-selected {
        background-color: $component-background;
        color: black;
      }
    }
  }
}