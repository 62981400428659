@import '../../styles/variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-color: black;
  padding: 0px 200px;
  position: sticky;
  bottom: 0px;
  > div, a {
    color: white;
    margin-right: 10px;
  }
}