@import '../../styles/variables';
@import '../../styles/mixins';

.radio {
  position: relative;
  background-color: transparent;
  z-index: 0;
  input {
    display: none;
    &:checked {
      & ~ .radio-background {
        transition: transform 0.5s ease-in-out;
      }
      & + label {
        color: white;
      }
      &:first-of-type ~ .radio-background {
        transform: translateX(0);
      }
      &:nth-child(3) ~ .radio-background {
        transform: translateX(84px);
      }
      &:nth-child(5) ~ .radio-background {
        transform: translateX(113px);
      }
    }
  }
  .radio-background,
  .label {
    width: 84px;
    height: 22px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    cursor: pointer;
    outline: $border;
    user-select: none;
  }
  &.with-3 {
    .radio-background, .label {
      width: 56px;
    }
    input:checked:nth-child(3) ~ .radio-background {
      transform: translateX(56px);
    }
  }
  .radio-background {
    background-color: var(--primary-color);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .radio-element {
    @include radio-element;
    align-items: baseline;
    &.disabled {
      .radio-input {
        input + .radio-control::before {
          box-shadow: inset 9px 9px $border-color;
        }
      }
      b {
        opacity: .2;
      }
    }
  }
}
