@import '/src/storybook/styles/variables';

.sizer-admin {
  .header {
    border-bottom: 1px solid black;
    padding: 20px;
    color: var(--primary-color);
  }
  .content {
    height: 100%;
    padding: 20px;
    .users-table {
      max-height: 800px;
      overflow-y: scroll;
      width: 80%;
      margin: 50px auto;
      display: grid;
      grid-template-columns: minmax(20%, 300px) 1fr 4%;
      row-gap: 10px;
      div {
        align-self: center;
        input {
          text-align: left;
        }
      }
      .actions {
        justify-self: center;
        svg {
          cursor: pointer;
        }
      }
    }
  }
}