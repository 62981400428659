@import '../../styles/variables';

.toggle {
  display: flex;
  align-items: center;
  input {
    display: none;
    margin: 0px;
    & + label {
      display: inline-block;
      width: 43px;
      height: 18px;
      cursor: pointer;
      background: var(--primary-color);
      border-radius: 2em;
      padding: 5px;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 45%;
        height: 100%;
      }
      &:after {
        left: 0;
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
      }
      &:before {
        display: none;
      }
    }
    &:checked + label:after {
      left: 55%;
    } 
  }
  &.boolean input:not(:checked) + label {
    background: $secondary-text-color;
  }
  div {
    font-weight: 600;
    &:first-child {
      margin-right: 40px;
    }
    &:last-child {
      margin-left: 28px;
    }
  }
}