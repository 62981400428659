.page-404 {
  text-align: center;
  h1 {
    margin-top: 200px;
    font-size: 100px;
    color: grey;
  }
  div {
    margin-top: 20px;
    font-size: 20px;
  }
}