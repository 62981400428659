@import '/src/storybook/styles/variables';

.system-capacity {
  .tabs-container > div:last-child {
    flex: 0%;
    width: 100%;
  }
  .requirement-box-title {
    padding: 24px 24px 24px 30px;
  }
  .requirement-box.first-tab {
    .requirement-row:first-child {
      margin-top: 0px;
    }
    .toggle, .cold-capacity {
      margin-top: 16px;
    }
    .requirement-row.cold-capacity b {
      margin-right: 16px;
    }
  }
  .requirement-box.second-tab {
    .toggle div:first-child {
      margin-right: 36px;
    }
    .number-of-enclosures input {
      width: 182px;
    }
    .cold-capacity b {
      margin-right: 10px;
    }
    .customize-ra {
      margin-top: 16px;
    }
  }
  .dropdown {
    margin-left: auto;
  }
  .tab.by-sizing {
    padding-left: 50px;
    width: 99px;
  }
}